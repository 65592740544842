import Vue from 'vue'
import { apiGetAuth, apiPostAuth, apiGetPdfAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_RHPP } from '@/utils/api-url'
import { notificationDanger } from '@/utils/notification'

const state = {
  listRhpp: {
    data: [],
    meta: {}
  },
  isCalculated: null,
  modalAddCalculate: {},
  modalDelete: {},
  listSapronakDoc: [],
  listSapronakPakan: [],
  listSapronakOvk: [],
  listReturn: [],
  meta: {
    page: 1,
    limit: 10
  },
  metaListSapronak: {
    by_item_category: 'feed,doc,ovk',
    is_all: true,
    log_cycle_code: '',
    transaction_type: 'delivery'
  },
  metaListReturn: {
    is_all: true,
    log_cycle_code: '',
    transaction_type: 'return'
  },
  metaListResultProduction: {
    is_all: true,
    log_cycle_code: ''
  },
  metaLogCycle: {
    limit: 10,
    is_rhpp: true
  },
  fileChanges: false,
  docFileIsUploaded: false,
  ovkFileIsUploaded: false,
  feedFileIsUploaded: false,
  weighingFileIsUploaded: false
}

const mutations = {
  "SET_LIST_RHPP": (state, payload) => {
    Vue.set(state, 'list', payload)
  },
  "SET_LIST_LOG_CYCLE": (state, payload) => {
    Vue.set(state, 'listLogCycle', payload)
  },
  "SET_DATA_LOG_CYCLE": (state, payload) => {
    Vue.set(state, 'dataLogCycle', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_META_LOG_CYCLE": (state, payload) => {
    Vue.set(state, 'metaLogCycle', payload)
  },
  "SET_META_SAPRONAK": (state, payload) => {
    Vue.set(state, 'metaListSapronak', payload)
  },
  "SET_META_RETURN": (state, payload) => {
    Vue.set(state, 'metaListReturn', payload)
  },
  "SET_META_RESULT_PRODUCTION": (state, payload) => {
    Vue.set(state, 'metaListResultProduction', payload)
  },
  "SET_DETAIL_RHPP": (state, payload) => {
    Vue.set(state, 'detail', payload)
  },
  "SET_LIST_RHPP_SAPRONAK_DOC": (state, payload) => {
    Vue.set(state, 'listSapronakDoc', payload)
  },
  "SET_LIST_RHPP_SAPRONAK_PAKAN": (state, payload) => {
    Vue.set(state, 'listSapronakPakan', payload)
  },
  "SET_LIST_RHPP_SAPRONAK_OVK": (state, payload) => {
    Vue.set(state, 'listSapronakOvk', payload)
  },
  "SET_LIST_RHPP_RETURN": (state, payload) => {
    Vue.set(state, 'listReturn', payload)
  },
  "SET_LIST_RESULT_PRODUCTION": (state, payload) => {
    Vue.set(state, 'listResultProduction', payload)
  },
  "SET_ADD_RHPP": (state, payload) => {
    Vue.set(state, 'dataCreate', payload)
  },
  "SET_CALCULATE_RHPP": (state, payload) => {
    Vue.set(state, 'dataCalculateRhpp', payload)
  },
  "SET_DOWNLOAD_PDF_RHPP": (state, payload) => {
    Vue.set(state, 'downloadPdfRhpp', payload)
  },
  "SET_UPLOAD_FILE_RHPP": (state, payload) => {
    Vue.set(state, 'uploadFileRhpp', payload)
  },
  "SET_APPROVAL_RHPP": (state, payload) => {
    Vue.set(state, 'dataApprovalRhpp', payload)
  },
  "SET_UPDATE_RHPP": (state, payload) => {
    Vue.set(state, 'dataUpdateRhpp', payload)
  },
  "SET_DELETE_RHPP": (state, payload) => {
    Vue.set(state, 'dataDeleteRhpp', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_MODAL_ADD_CALCULATE": (state, payload) => {
    Vue.set(state, 'modalAddCalculate', payload)
  },
  "SET_IS_CALCULATE": (state, payload) => {
    Vue.set(state, 'isCalculated', payload)
  },
  "SET_FILE_CHANGES": (state, payload) => {
    Vue.set(state, 'fileChanges', payload)
  },
  "SET_WAIT_FILE_DOC": (state, payload) => {
    Vue.set(state, 'docFileIsUploaded', payload)
  },
  "SET_WAIT_FILE_OVK": (state, payload) => {
    Vue.set(state, 'ovkFileIsUploaded', payload)
  },
  "SET_WAIT_FILE_FEED": (state, payload) => {
    Vue.set(state, 'feedFileIsUploaded', payload)
  },
  "SET_WAIT_FILE_WEIGHING": (state, payload) => {
    Vue.set(state, 'weighingFileIsUploaded', payload)
  },
  "RESPONSE_IMPORT_RHPP": (state, payload) => {
    Vue.set(state, 'responseImportRhpp', payload)
  }
}

const actions = {
  async getListRhpp(context, data) {
    try {
      const response = await apiGetAuth(API_RHPP.LIST, data)
      context.commit('SET_LIST_RHPP', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getListLogCycle(context, data) {
    try {
      if ('field' in data) {
        const asArray = Object.entries(data)
        const filtered = asArray.filter(([key, value]) => key !== 'field' && value !== 'edit rhpp')
        const objectPayload = Object.fromEntries(filtered)
        const response = await apiGetAuth(API_RHPP.LIST_LOG_CYCLE, objectPayload)
        context.commit('SET_DATA_LOG_CYCLE', response.data.data)
      } else {
        const response = await apiGetAuth(API_RHPP.LIST_LOG_CYCLE, data)
        context.commit('SET_LIST_LOG_CYCLE', response.data.data)
      }
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getRhppDetail(context, data) {
    try {
      //Get RHPP Detail
      const response = await apiGetAuth(API_RHPP.DETAIL(data))
      context.commit('SET_DETAIL_RHPP', response.data)
      let listSapronakDoc= []
      let listSapronakPakan= []
      let listSapronakOvk= []
      let listReturn= []      
      const responseSt = response.data.data
      
      //Get DOC
      const responseDoc = responseSt.stock_transfer_doc
      responseDoc.filter(item => {
        listSapronakDoc.push(item)
      })
      context.commit('SET_LIST_RHPP_SAPRONAK_DOC', listSapronakDoc)
      
      //Get Feed
      const responseFeed = responseSt.stock_transfer_feed
      responseFeed.filter(item => {
        listSapronakPakan.push(item)
      })
      context.commit('SET_LIST_RHPP_SAPRONAK_PAKAN', listSapronakPakan)
      
      //Get OVK
      const responseOvk = responseSt.stock_transfer_ovk
      responseOvk.filter(item => {
        listSapronakOvk.push(item)
      })
      context.commit('SET_LIST_RHPP_SAPRONAK_OVK', listSapronakOvk)
      
      //Get Return
      const responseReturn = responseSt.stock_transfer_return
      responseReturn.filter(item => {
        listReturn.push(item)
      })
      context.commit('SET_LIST_RHPP_RETURN', listReturn)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getListResultProduction(context, data) {
    try {
      const response = await apiGetAuth(API_RHPP.LIST_RESULT_PRODUCTION, data)
      context.commit('SET_LIST_RESULT_PRODUCTION', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async createRhpp(context, data) {
    try {
      const response = await apiPostAuth(API_RHPP.ADD, data)
      context.commit('SET_ADD_RHPP', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async calculateRhpp(context, data) {
    try {
      const response = await apiPostAuth(API_RHPP.CALCULATE, data)
      context.commit('SET_IS_CALCULATE', true)
      context.commit('SET_CALCULATE_RHPP', response.data.data)
      const responseSt = response.data.data
      let listSapronakDoc= []
      let listSapronakPakan= []
      let listSapronakOvk= []
      let listReturn= []  

      //Get DOC
      const responseDoc = responseSt.ukirama_stock_transfer_doc
      if(responseDoc != null){
        responseDoc.filter(item => {
          listSapronakDoc.push(item)
        })
        context.commit('SET_LIST_RHPP_SAPRONAK_DOC', listSapronakDoc)
      } else {
        context.commit('SET_LIST_RHPP_SAPRONAK_DOC', {})
      }

      //Get Feed
      const responseFeed = responseSt.ukirama_stock_transfer_feed
      if(responseFeed != null){
        responseFeed.filter(item => {
          listSapronakPakan.push(item)
        })
        context.commit('SET_LIST_RHPP_SAPRONAK_PAKAN', listSapronakPakan)
      } else {
        context.commit('SET_LIST_RHPP_SAPRONAK_PAKAN', {})
      }

      //Get OVK
      const responseOvk = responseSt.ukirama_stock_transfer_ovk
      if(responseOvk != []){
        responseOvk.filter(item => {
          listSapronakOvk.push(item)
        })
        context.commit('SET_LIST_RHPP_SAPRONAK_OVK', listSapronakOvk)
      } else {
        context.commit('SET_LIST_RHPP_SAPRONAK_OVK', {})
      }

      //Get Return
      const responseReturn = responseSt.ukirama_stock_transfer_return
      if(responseReturn != null){
        responseReturn.filter(item => {
          listReturn.push(item)
        })
        context.commit('SET_LIST_RHPP_RETURN', listReturn)
      } else {
        context.commit('SET_LIST_RHPP_RETURN', {})
      }

      context.commit('SET_MODAL_ADD_CALCULATE', responseSt)
      context.commit('SET_DETAIL_RHPP', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async getPrintPdfRhpp(context, data) {
    try {
      const response = await apiGetPdfAuth(API_RHPP.DOWNLOAD_PDF(data))
      context.commit('SET_DOWNLOAD_PDF_RHPP', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async uploadRhppFileDoc(context, data) {
    try {
      const idRhpp = data.get('id')
      const response = await apiPutAuth(API_RHPP.UPLOAD_DOC_FILE(idRhpp), data)
      context.commit('SET_WAIT_FILE_DOC',response.data.message == 'OK')
      context.commit('SET_UPLOAD_FILE_RHPP', response.data.data)
      
    } catch (err) {
      context.commit('SET_WAIT_FILE_DOC',err.response.data.message)
      throw err
    }
  },
  async uploadRhppFileOvk(context, data) {
    try {
      const idRhpp = data.get('id')
      const response = await apiPutAuth(API_RHPP.UPLOAD_OVK_FILE(idRhpp), data)
      context.commit('SET_WAIT_FILE_OVK',response.data.message == 'OK')
      context.commit('SET_UPLOAD_FILE_RHPP', response.data.data)
    } catch (err) {
      context.commit('SET_WAIT_FILE_OVK',err.response.data.message)
      throw err
    }
  },
  async uploadRhppFileFeed(context, data) {
    try {
      const idRhpp = data.get('id')
      const response = await apiPutAuth(API_RHPP.UPLOAD_FEED_FILE(idRhpp), data)
      context.commit('SET_WAIT_FILE_FEED',response.data.message == 'OK')
      context.commit('SET_UPLOAD_FILE_RHPP', response.data.data)
    } catch (err) {
      context.commit('SET_WAIT_FILE_FEED',err.response.data.message)
      throw err
    }
  },
  async uploadRhppFileWeighing(context, data) {
    try {
      const idRhpp = data.get('id')
      const response = await apiPutAuth(API_RHPP.UPLOAD_WEIGHING_FILE(idRhpp), data)
      context.commit('SET_WAIT_FILE_WEIGHING',response.data.message == 'OK')
      context.commit('SET_UPLOAD_FILE_RHPP', response.data.data)
    } catch (err) {
      context.commit('SET_WAIT_FILE_WEIGHING',err.response.data.message)
      throw err
    }
  },
  async getApproval(context, data) {
    try {
      const response = await apiPutAuth(API_RHPP.APPROVAL, data)
      context.commit('SET_APPROVAL_RHPP', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async getEditRhpp(context, data) {
    try {
      const response = await apiPutAuth(API_RHPP.UPDATE, data)
      context.commit('SET_UPDATE_RHPP', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async getDeleteRhpp(context, data) {
    try {
      const response = await apiDeleteAuth(API_RHPP.DELETE(data))
      context.commit('SET_DELETE_RHPP', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async importRhpp(context, data){
    try {
      let response
      const codeApi = data.get('code_api')
      if(codeApi == 0){
        response = await apiPostAuth(API_RHPP.IMPORT_RHPP_INFO, data)
      } else if (codeApi == 1){
        response = await apiPostAuth(API_RHPP.IMPORT_RHPP_RECAP, data)
      } else {
        response = await apiPostAuth(API_RHPP.IMPORT_RHPP_ST, data)
      }
      context.commit('RESPONSE_IMPORT_RHPP', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  list: state => {
    return state.list
  },
  listLogCycle: state => {
    return state.listLogCycle
  },
  dataLogCycle: state => {
    return state.dataLogCycle
  },
  listSapronakDoc: state => {
    return state.listSapronakDoc
  },
  listSapronakPakan: state => {
    return state.listSapronakPakan
  },
  listSapronakOvk: state => {
    return state.listSapronakOvk
  },
  listReturn: state => {
    return state.listReturn
  },
  listResultProduction: state => {
    return state.listResultProduction
  },
  meta: state => {
    return state.meta
  },
  metaListSapronak: state => {
    return state.metaListSapronak
  },
  metaListResultProduction: state => {
    return state.metaListResultProduction
  },
  metaLogCycle: state => {
    return state.metaLogCycle
  },
  detail: state => {
    return state.detail
  },
  dataCreate: state => {
    return state.dataCreate
  },
  dataCalculateRhpp: state => {
    return state.dataCalculateRhpp
  },
  downloadPdfRhpp: state => {
    return state.downloadPdfRhpp
  },
  uploadFileRhpp: state => {
    return state.uploadFileRhpp
  },
  dataApprovalRhpp: state => {
    return state.dataApprovalRhpp
  },
  dataUpdateRhpp: state => {
    return state.dataUpdateRhpp
  },
  dataDeleteRhpp: state => {
    return state.dataDeleteRhpp
  },
  modalDelete: state => {
    return state.modalDelete
  },
  modalAddCalculate: state => {
    return state.modalAddCalculate
  },
  isCalculated: state => {
    return state.isCalculated
  },
  fileChanges: state => {
    return state.fileChanges
  },
  docFileIsUploaded: state => {
    return state.docFileIsUploaded
  },
  ovkFileIsUploaded: state => {
    return state.ovkFileIsUploaded
  },
  feedFileIsUploaded: state => {
    return state.feedFileIsUploaded
  },
  weighingFileIsUploaded: state => {
    return state.weighingFileIsUploaded
  },
  responseImportRhpp: state => {
    return state.responseImportRhpp
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
