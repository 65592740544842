<template>
  <div
    class="uk-width-1-1"
  >
    <div class="uk-card uk-card-default kandang-card">
      <h4 class="label-green card-title">
        Input - Closing Production
      </h4>
      <hr>
      <div class="uk-grid">
        <chart-production-vue :chart-props="chartDoc" />
        <chart-production-vue :chart-props="chartFeed" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChartProductionVue from './chart-production/ChartProduction.vue'

export default{
  components:{
    ChartProductionVue
  },
  data () {
    return{
      chartDoc: {},
      chartFeed: {}
    }
  },
  computed:{
    ...mapGetters({
      dataInputClosingDoc: 'productionDashboard/dataInputClosingDoc',
      dataInputClosingFeed: 'productionDashboard/dataInputClosingFeed'
    })
  },
  watch:{
    dataInputClosingDoc(){
      this.chartDoc = {
        range: this.dataInputClosingDoc.chart.range,
        data: this.dataInputClosingDoc.chart.dataset,
        title: 'Doc',
        yLegend: 'Ekor',
        backgroundColor: '#52E0A5',
        acqumulation: this.dataInputClosingDoc.number_of_chickens.accumulation,
        currentMonth: this.dataInputClosingDoc.number_of_chickens.current_month,
        unit: 'Birds'
      }
    },
    dataInputClosingFeed(){
      this.chartFeed = {
        range: this.dataInputClosingFeed.chart.range,
        data: this.dataInputClosingFeed.chart.dataset,
        title: 'Feed',
        yLegend: 'Kgs',
        backgroundColor: '#907DFF',
        acqumulation: this.dataInputClosingFeed.number_of_chickens.accumulation,
        currentMonth: this.dataInputClosingFeed.number_of_chickens.current_month,
        unit: 'Kgs'
      }
    }
  }
}


</script>

