<template>
  <div
    class="uk-width-1-1"
  >
    <div class="uk-card uk-card-default kandang-card">
      <h4 class="label-green card-title">
        Output - Closing Production
      </h4>
      <hr>
      <div class="uk-grid">
        <chart-production-vue :chart-props="chartLivebirds" />
        <chart-production-vue :chart-props="chartLivebirdsBw" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChartProductionVue from './chart-production/ChartProduction.vue'

export default{
  components:{
    ChartProductionVue
  },
  data () {
    return{
      chartLivebirds: {},
      chartLivebirdsBw: {}
    }
  },
  computed:{
    ...mapGetters({
      dataOutputClosingLivebirds: 'productionDashboard/dataOutputClosingLivebirds',
      dataOutputClosingLivebirdsBw: 'productionDashboard/dataOutputClosingLivebirdsBw'
    })
  },
  watch:{
    dataOutputClosingLivebirds(){
      this.chartLivebirds = {
        range: this.dataOutputClosingLivebirds.chart.range,
        data: this.dataOutputClosingLivebirds.chart.dataset,
        title: 'Livebirds',
        yLegend: 'Ekor',
        backgroundColor: '#82C3FF',
        acqumulation: this.dataOutputClosingLivebirds.number_of_chickens.accumulation,
        currentMonth: this.dataOutputClosingLivebirds.number_of_chickens.current_month,
        unit: 'Birds'
      }
    },
    dataOutputClosingLivebirdsBw(){
      this.chartLivebirdsBw = {
        range: this.dataOutputClosingLivebirdsBw.chart.range,
        data: this.dataOutputClosingLivebirdsBw.chart.dataset,
        title: 'Livebirds.Bw',
        yLegend: 'Kgs',
        backgroundColor: '#EDA55C',
        acqumulation: this.dataOutputClosingLivebirdsBw.number_of_chickens.accumulation,
        currentMonth: this.dataOutputClosingLivebirdsBw.number_of_chickens.current_month,
        unit: 'Kgs'
      }
    }
  }
}


</script>

