<template>
  <div
    class="uk-width-1-1"
  >
    <div class="uk-card uk-card-default kandang-card">
      <h4 class="label-green card-title">
        Performance - Closing Production
      </h4>
      <hr>
      <div class="uk-grid">
        <chart-production-vue :chart-props="chartMortality" />
        <chart-production-vue :chart-props="chartAvrWeight" />
        <chart-production-vue :chart-props="chartFcr" />
        <chart-production-vue :chart-props="chartLivebirdAge" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChartProductionVue from './chart-production/ChartProduction.vue'

export default{
  components:{
    ChartProductionVue
  },
  data () {
    return{
      chartMortality: {},
      chartFcr: {},
      chartAvrWeight: {},
      chartLivebirdAge: {}
    }
  },
  computed:{
    ...mapGetters({
      dataPerformanceClosingMortality: 'productionDashboard/dataPerformanceClosingMortality',
      dataPerformanceClosingAverageBw: 'productionDashboard/dataPerformanceClosingAverageBw',
      dataPerformanceClosingFcr: 'productionDashboard/dataPerformanceClosingFcr',
      dataPerformanceClosingHarvestAge: 'productionDashboard/dataPerformanceClosingHarvestAge'
    })
  },
  watch:{
    dataPerformanceClosingMortality(){
      this.chartMortality = {
        range: this.dataPerformanceClosingMortality.chart.range,
        data: this.dataPerformanceClosingMortality.chart.dataset,
        title: 'Mortality',
        yLegend: 'Presentase',
        backgroundColor: '#F92626',
        acqumulation: this.dataPerformanceClosingMortality.number_of_chickens.accumulation,
        currentMonth: this.dataPerformanceClosingMortality.number_of_chickens.current_month,
        unit: 'Birds'
      }
    },
    dataPerformanceClosingAverageBw(){
      this.chartAvrWeight = {
        range: this.dataPerformanceClosingAverageBw.chart.range,
        data: this.dataPerformanceClosingAverageBw.chart.dataset,
        title: 'FCR',
        yLegend: 'Kgs',
        backgroundColor: '#045A95',
        acqumulation: this.dataPerformanceClosingAverageBw.number_of_chickens.accumulation,
        currentMonth: this.dataPerformanceClosingAverageBw.number_of_chickens.current_month,
        unit: 'Kgs'
      }
    },
    dataPerformanceClosingFcr(){
      this.chartFcr = {
        range: this.dataPerformanceClosingFcr.chart.range,
        data: this.dataPerformanceClosingFcr.chart.dataset,
        title: 'Bobor Rataan',
        yLegend: 'Kgs',
        backgroundColor: '#88C06B',
        acqumulation: this.dataPerformanceClosingFcr.number_of_chickens.accumulation,
        currentMonth: this.dataPerformanceClosingFcr.number_of_chickens.current_month,
        unit: 'kg'
      }
    },
    dataPerformanceClosingHarvestAge(){
      this.chartLivebirdAge = {
        range: this.dataPerformanceClosingHarvestAge.chart.range,
        data: this.dataPerformanceClosingHarvestAge.chart.dataset,
        title: 'Livebirds Age',
        yLegend: 'Hari',
        backgroundColor: '#EDA55C',
        acqumulation: this.dataPerformanceClosingHarvestAge.number_of_chickens.accumulation,
        currentMonth: this.dataPerformanceClosingHarvestAge.number_of_chickens.current_month,
        unit: 'Hari'
      }
    }
  }
}


</script>

