import Vue from 'vue'
import { apiGetAuth, apiPostAuth } from '@/utils/api'
import { API_SAPRONAK } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  meta: {page:1,limit:10, transaction_type: 'delivery'},
  detail_sapronak: {},
  table_detail: {meta:{},data:[]},
  metaTable: {page:1,limit:10},
  sapronak: {meta:{},data:[]},
  metaRetur: {page:1,limit:10, transaction_type: 'return'},
  detail_retur: {},
  retur: {meta:{},data:[]},
  sync_data: {}
}

const mutations = {
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_SAPRONAK": (state, payload) => {
    Vue.set(state, 'sapronak', payload)
  },
  "SET_DETAIL_SAPRONAK": (state, payload) => {
    Vue.set(state, 'detail_sapronak', payload)
  },
  "SET_TABLE_DETAIL": (state, payload) => {
    Vue.set(state, 'table_detail', payload)
  },
  "SET_META_TABLE": (state, payload) => {
    Vue.set(state, 'metaTable', payload)
  },
  "SET_META_RETUR": (state, payload) => {
    Vue.set(state, 'metaRetur', payload)
  },
  "SET_RETUR": (state, payload) => {
    Vue.set(state, 'retur', payload)
  },
  "SET_DETAIL_RETUR": (state, payload) => {
    Vue.set(state, 'detail_retur', payload)
  },
  "SYNC_DATA": (state, payload) => {
    Vue.set(state, 'sync_data', payload)
  },
  "RESPONSE_IMPORT_ST": (state, payload) => {
    Vue.set(state, 'responseSt', payload)
  },
  "RESPONSE_IMPORT_ST_DETAIL": (state, payload) => {
    Vue.set(state, 'responseStDetail', payload)
  }
}

const actions = {
  async getSapronak(context, data) {
    try {
      const response = await apiGetAuth(API_SAPRONAK.LIST, data)
      context.commit('SET_SAPRONAK', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getSapronakDetail(context, data) {
    try {
      const response = await apiGetAuth(API_SAPRONAK.DETAIL(data))
      context.commit('SET_DETAIL_SAPRONAK', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getSapronakTableDetail(context, data) {
    try {
      const response = await apiGetAuth(API_SAPRONAK.TABLE_DETAIL, data)
      context.commit('SET_TABLE_DETAIL', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getRetur(context, data) {
    try {
      const response = await apiGetAuth(API_SAPRONAK.LIST, data)
      context.commit('SET_RETUR', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getReturDetail(context, data) {
    try {
      const response = await apiGetAuth(API_SAPRONAK.DETAIL(data))
      context.commit('SET_DETAIL_RETUR', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async syncSapronak(context, data) {
    try {
      const responseStatus = await apiGetAuth(API_SAPRONAK.CHECK_SYNC_STATUS, data)
      if(responseStatus.data.data.status == 'COMPLETED'){
        const response = await apiGetAuth(API_SAPRONAK.SYNC, data)
        context.commit('SYNC_DATA', response.data)
      } else {
        notificationDanger({message:'Sedang ada proses sync yang berlangsung!'})
      }
    } catch (err) {
      notificationDanger(err)
    }
  },
  async importSt(context, data) {
    try {
      const response = await apiPostAuth(API_SAPRONAK.IMPORT_ST, data)
      context.commit('RESPONSE_IMPORT_ST', response.data)
    } catch (error) {
      context.commit('RESPONSE_IMPORT_ST', (error.response.data || error))
      notificationDanger(error)
    }
  },
  async importStDetail(context, data) {
    try {
      const response = await apiPostAuth(API_SAPRONAK.IMPORT_ST_DETAIL, data)
      context.commit('RESPONSE_IMPORT_ST_DETAIL', response.data)
    } catch (error) {
      context.commit('RESPONSE_IMPORT_ST_DETAIL', (error.response.data || error))
      notificationDanger(error)
    }
  }
}

const getters = {
  meta: state => {
    return state.meta
  },
  sapronak: state => {
    return state.sapronak
  },
  detail_sapronak: state => {
    return state.detail_sapronak
  },
  table_detail: state => {
    return state.table_detail
  },
  metaTable: state => {
    return state.metaTable
  },
  metaRetur: state => {
    return state.metaRetur
  },
  retur: state => {
    return state.retur
  },
  detail_retur: state => {
    return state.detail_retur
  },
  sync_data: state => {
    return state.sync_data
  },
  responseSt: state => {
    return state.responseSt
  },
  responseStDetail: state => {
    return state.responseStDetail
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
