<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-1-3@s font-header">
        {{ headerTitle }}
      </div>
      <filter-production />
      <div
        uk-grid
        class="uk-width-1-1"
      >
        <!-- start input closing production -->
        <input-closing-production />
        <!-- end input closing production -->

        <!-- start output closing production -->
        <output-closing-production />
        <!-- end output closing production -->

        <!-- start performance closing production -->
        <performance-closing-production />
        <!-- end performance closing production -->
        
        <closing-production />
        <current-production />
      </div>
    </div>
  </div>
</template>

<script>
import getHeaderTitle from "@/utils/header-title"
import filterProduction from "./Filter.vue"
import inputClosingProduction from "./Component/InputClosingProduction"
import outputClosingProduction from "./Component/OutputClosingProduction"
import performanceClosingProduction from "./Component/PerformanceClosingProduction"
import closingProduction from "./Component/ClosingProduction"
import currentProduction from "./Component/CurrentProduction"


export default{
  components: {
    filterProduction,
    inputClosingProduction,
    outputClosingProduction,
    performanceClosingProduction,
    closingProduction,
    currentProduction
  },
  data() {
    return {
      headerTitle:  getHeaderTitle(this.$route.fullPath)
    }
  }
}

</script>
