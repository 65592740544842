<template>
  <div class="uk-width-2-3@s uk-flex uk-flex-right uk-margin-medium-bottom">
    <div class="uk-grid">
      <!-- Form for Jenis Kandang -->
      <div class="uk-width-1-2@s">
        <div
          uk-grid
          class="uk-grid-small uk-grid"
        >
          <div class="uk-width-1-2@s filter-margin uk-margin-remove-right">
            <label
              class="uk-form-label label-green"
            >Jenis Kandang</label>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-form-controls">
              <multiselect 
                id="cage-category-type"
                v-model="cageTypeChoose" 
                label="name"
                name="cage-category-type" 
                track-by="name" 
                open-direction="bottom" 
                :options="cageTypes" 
                :searchable="true" 
                :close-on-select="true" 
                :max-height="500" 
                :show-no-results="true"
                :allow-empty="false"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
        
      <!-- Form for Periode -->
      <div class="uk-width-1-2@s">
        <div
          uk-grid
          class="uk-grid-small uk-grid"
        >
          <div class="uk-width-1-3@s filter-margin uk-margin-remove-right">
            <label
              class="uk-form-label label-green"
            >Periode</label>
          </div>
          <div class="uk-width-2-3@s">
            <div class="uk-form-controls">
              <multiselect 
                id="periode_type" 
                v-model="periodeTypeChoose"
                name="periode_type" 
                label="name"
                track-by="name" 
                open-direction="bottom" 
                :options="periodeTypes" 
                :searchable="true" 
                :close-on-select="true" 
                :max-height="500" 
                :show-no-results="true"
                :allow-empty="false"
              />
              <div
                v-if="openDatePicker"
                class="uk-position-absolute date-picker-margin"
              >
                <datepicker
                  ref="datePeriode"
                  v-model="datePeriodeInput"
                  v-validate="'required'"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="mulai_persiapan"
                  type="month"
                  range-separator=" - "
                  range
                  :class="{'uk-form-danger': errors.has('mulai_persiapan')}"
                />
                <div
                  v-show="errors.has('mulai_persiapan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('mulai_persiapan') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/id'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { formatDateYYYYMM } from '@/utils/formater'

export default {
  components:{
    Datepicker
  },
  data(){
    return {
      cageTypes: [{name: 'p500'}, {name: 'p5000'}],
      cageTypeChoose: {name: 'p500'},
      periodeTypes: [{name:'Today minus 12 months'}, {name: 'Custom'}],
      periodeTypeChoose: {name:'Today minus 12 months'},
      cage_type: 'p500',
      start_date: '',
      end_date: '',
      openDatePicker: false,
      datePeriodeInput:''
    }
  },
  computed:{
    ...mapGetters({
      metaProduction: 'productionDashboard/dataMeta',
      dataMetaClosing: 'productionDashboard/dataMetaClosing'
    })
  },
  watch:{
    async cageTypeChoose(){
      this.cage_type = this.cageTypeChoose.name
      this.setMeta({
        ...this.metaProduction,
        cage_type: this.cage_type
      })
      this.setMetaClosing({
        ...this.metaClosing,
        cage_type: this.cage_type
      })
      await this.callApis()
      await this.handlerClosing()
    },
    periodeTypeChoose() {
      if (this.periodeTypeChoose.name.toLowerCase() === 'custom') {
        this.openDatePicker = true
      } else {
        this.handleTwelveMonths()
      }
    },
    async datePeriodeInput(){
      const start = this.datePeriodeInput[0]
      const end = this.datePeriodeInput[1]
      
      const formattedDateStart = this.formatDate(start)
      const formattedDateEnd = this.formatDate(end)

      this.start_date = formattedDateStart
      this.end_date = formattedDateEnd
      this.setMeta({
        ...this.metaProduction,
        start_date: this.start_date,
        end_date: this.end_date
      })
      this.setMetaClosing({
        ...this.metaClosing,
        start_date: this.start_date,
        end_date: this.end_date
      })
      await this.callApis()
      await this.handlerClosing()
    }
  },
  async mounted(){
    await this.handleFirstFilter()
  },
  methods:{
    ...mapActions({
      getInputClosingProduction: 'productionDashboard/getInputClosingProduction',
      getOutputClosingProduction: 'productionDashboard/getOutputClosingProduction',
      getPerformanceClosingProduction: 'productionDashboard/getPerformanceClosingProduction',
      getClosingProduction: 'productionDashboard/getClosingProduction'
    }),
    ...mapMutations({
      setMeta: 'productionDashboard/SET_META',
      setMetaClosing: 'productionDashboard/SET_META_CLOSING_PRODUCTION'
    }),
    async handleFirstFilter(){
      await this.handleTwelveMonths()
    },
    async handleTwelveMonths(){
      const today = new Date()
      const twelveMonthsAgo = new Date(today)
      twelveMonthsAgo.setMonth(today.getMonth() - 12)

      const formattedDateStart = this.formatDate(twelveMonthsAgo)
      const formattedDateEnd = this.formatDate(today)

      this.start_date = formattedDateStart
      this.end_date = formattedDateEnd
      this.setMeta({
        ...this.metaProduction,
        start_date: this.start_date,
        end_date: this.end_date,
        cage_type: this.cage_type
      })
      this.setMetaClosing({
        ...this.metaClosing,
        start_date: this.start_date,
        end_date: this.end_date,
        cage_type: this.cage_type
      })
      await this.callApis()
    },
    formatDate(d){
      return formatDateYYYYMM(d)
    },
    async callApis() {
      await this.handleApi('feed')
      await this.handleApi('doc')
      await this.handleApi('chicken')
      await this.handleApi('weight')
      await this.handleApi('mortality')
      await this.handleApi('fcr')
      await this.handleApi('average_body_weight')
      await this.handleApi('harvest_age')
      await this.handlerClosing()
    },
    async handleApi(type){
      if(type == 'feed' || type == 'doc'){
        await this.handlerInputClosings(type)
      } else if(type == 'chicken' || type == 'weight'){
        await this.handlerOutputClosings(type)
      } else {
        await this.handlerPerformanceClosings(type)
      }
    },
    async handlerInputClosings(type) {
      this.setMeta({
        ...this.metaProduction,
        cage_type: this.cage_type,
        start_date: this.start_date,
        end_date: this.end_date,
        transaction_type: type
      })
      await this.getInputClosingProduction(this.metaProduction)
    },
    async handlerOutputClosings(type) {
      this.setMeta({
        ...this.metaProduction,
        cage_type: this.cage_type,
        start_date: this.start_date,
        end_date: this.end_date,
        transaction_type: type
      })
      await this.getOutputClosingProduction(this.metaProduction)
    },
    async handlerPerformanceClosings(type){
      this.setMeta({
        ...this.metaProduction,
        cage_type: this.cage_type,
        start_date: this.start_date,
        end_date: this.end_date,
        transaction_type: type
      })
      await this.getPerformanceClosingProduction(this.metaProduction)
    },
    async handlerClosing(){
      this.setMetaClosing({
        ...this.metaClosing,
        cage_type: this.cage_type,
        start_date: this.start_date,
        end_date: this.end_date
      })
      await this.getClosingProduction(this.dataMetaClosing)
    }
  }
}

</script>

<style scoped>
.date-picker-margin{
  right: 40px;
  margin-top: 20px;
}
</style>
