<template>
  <div
    class="uk-width-1-1@xs uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl"
  >
    <div class="uk-card uk-card-default kandang-card">
      <h4 class="label-green card-title">
        Current Production
      </h4>
      <table class="uk-width-expand uk-text-center">
        <thead>
          <tr>
            <th colspan="2" />
            <th class="text-black">
              Kandang
            </th>
            <th class="text-black">
              Ekor
            </th>
          </tr>
        </thead>
        <tbody>
          <tr height="70px">
            <td class="uk-text-left">
              <img
                v-lazy="`${images}/icon/chick-icon-regular.svg`"
                alt=""
              >
            </td>
            <td class="uk-text-left">
              Aktif
            </td>
            <td>{{ activeProduction.number_of_cages }}</td>
            <td>{{ activeProduction.number_of_chickens }}</td>
          </tr>
          <tr>
            <td class="uk-text-left">
              <img
                v-lazy="`${images}/icon/chick-icon-cross.svg`"
                alt=""
              >
            </td>
            <td class="uk-text-left">
              Rehat
            </td>
            <td>{{ restingProduction.number_of_cages }}</td>
            <td>{{ restingProduction.number_of_chickens }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { mapActions, mapGetters } from 'vuex'

export default{
  data(){
    return{
      images: PREFIX_IMAGE,
      activeProduction: {
        number_of_cages: 0,
        number_of_chickens: 0
      },
      restingProduction: {}
    }
  },
  computed:{
    ...mapGetters({
      dataCurrentProduction: 'productionDashboard/dataCurrentProduction'
    })
  },
  watch:{
    dataCurrentProduction(){
      if(this.dataCurrentProduction){
        this.activeProduction = this.dataCurrentProduction.active
        this.restingProduction = this.dataCurrentProduction.resting
      }
    }

  },
  async mounted(){
    await this.getCurrentProduction()
  },
  methods:{
    ...mapActions({
      getCurrentProduction: 'productionDashboard/getCurrentProduction'
    })
  }
}
</script>

<style scoped>
  img {
    width: 18px;
  }
</style>
