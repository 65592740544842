import Vue from 'vue'
import { apiGetAuth } from '@/utils/api'
import { API_PRODUCTION_DASHBOARD } from '@/utils/api-url'
import { notificationDanger } from '@/utils/notification'

const state = {
  dataCurrentProduction: {},
  dataMeta:{
    cage_type: '',
    start_date:'',
    end_date:'',
    transaction_type: ''
  },
  dataMetaClosing:{
    cage_type: '',
    start_date:'',
    end_date:''
  }
}

const mutations = {
  "SET_META": (state, payload) => {
    Vue.set(state, 'dataMeta', payload)
  },
  "SET_META_CLOSING_PRODUCTION": (state, payload) => {
    Vue.set(state, 'dataMetaClosing', payload)
  },
  "SET_DATA_INPUT_CLOSING_DOC": (state, payload) => {
    Vue.set(state, 'dataInputClosingDoc', payload)
  },
  "SET_DATA_INPUT_CLOSING_FEED": (state, payload) => {
    Vue.set(state, 'dataInputClosingFeed', payload)
  },
  "SET_DATA_OUTPUT_CLOSING_LIVEBIRDS": (state, payload) => {
    Vue.set(state, 'dataOutputClosingLivebirds', payload)
  },
  "SET_DATA_OUTPUT_CLOSING_LIVEBIRDS_BW": (state, payload) => {
    Vue.set(state, 'dataOutputClosingLivebirdsBw', payload)
  },
  "SET_DATA_PERFORMANCE_CLOSING_MORTALITY": (state, payload) => {
    Vue.set(state, 'dataPerformanceClosingMortality', payload)
  },
  "SET_DATA_PERFORMANCE_CLOSING_FCR": (state, payload) => {
    Vue.set(state, 'dataPerformanceClosingFcr', payload)
  },
  "SET_DATA_PERFORMANCE_CLOSING_AVERAGE_BW": (state, payload) => {
    Vue.set(state, 'dataPerformanceClosingAverageBw', payload)
  },
  "SET_DATA_PERFORMANCE_CLOSING_HARVEST_AGE": (state, payload) => {
    Vue.set(state, 'dataPerformanceClosingHarvestAge', payload)
  },
  "SET_DATA_CLOSING_PRODUCTION": (state, payload) => {
    Vue.set(state, 'dataClosingProduction', payload)
  },
  "SET_DATA_CURRENT_PRODUCTION": (state, payload) => {
    Vue.set(state, 'dataCurrentProduction', payload)
  }
}

const actions = {
  async getInputClosingProduction(context, data){
    try {
      const response = await apiGetAuth(API_PRODUCTION_DASHBOARD.INPUT_CLOSING, data)
      if(data.transaction_type == 'doc'){
        context.commit('SET_DATA_INPUT_CLOSING_DOC', response.data.data)
      } else {
        context.commit('SET_DATA_INPUT_CLOSING_FEED', response.data.data)
      }

    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOutputClosingProduction(context, data){
    try {
      const response = await apiGetAuth(API_PRODUCTION_DASHBOARD.OUTPUT_CLOSING, data)
      if(data.transaction_type == 'chicken'){
        context.commit('SET_DATA_OUTPUT_CLOSING_LIVEBIRDS', response.data.data)
      } else {
        context.commit('SET_DATA_OUTPUT_CLOSING_LIVEBIRDS_BW', response.data.data)
      }

    } catch (error) {
      notificationDanger(error)
    }
  },
  async getPerformanceClosingProduction(context, data){
    try {
      const response = await apiGetAuth(API_PRODUCTION_DASHBOARD.PERFORMANCE_CLOSING, data)
      if(data.transaction_type == 'mortality'){
        context.commit('SET_DATA_PERFORMANCE_CLOSING_MORTALITY', response.data.data)
      } else if(data.transaction_type == 'fcr') {
        context.commit('SET_DATA_PERFORMANCE_CLOSING_FCR', response.data.data)
      } else if(data.transaction_type == 'average_body_weight') {
        context.commit('SET_DATA_PERFORMANCE_CLOSING_AVERAGE_BW', response.data.data)
      } else {
        context.commit('SET_DATA_PERFORMANCE_CLOSING_HARVEST_AGE', response.data.data)
      }
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCurrentProduction(context){
    try {
      const response = await apiGetAuth(API_PRODUCTION_DASHBOARD.CURRENT_PRODUCTION)
      context.commit('SET_DATA_CURRENT_PRODUCTION', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getClosingProduction(context, data){
    try {
      const response = await apiGetAuth(API_PRODUCTION_DASHBOARD.CLOSING_PRODUCTION, data)
      context.commit('SET_DATA_CLOSING_PRODUCTION', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  dataMeta: state => {
    return state.dataMeta
  },
  dataMetaClosing: state => {
    return state.dataMetaClosing
  },
  dataInputClosingDoc: state => {
    return state.dataInputClosingDoc
  },
  dataInputClosingFeed: state => {
    return state.dataInputClosingFeed
  },
  dataOutputClosingLivebirds: state => {
    return state.dataOutputClosingLivebirds
  },
  dataOutputClosingLivebirdsBw: state => {
    return state.dataOutputClosingLivebirdsBw
  },
  dataPerformanceClosingMortality: state => {
    return state.dataPerformanceClosingMortality
  },
  dataPerformanceClosingFcr: state => {
    return state.dataPerformanceClosingFcr
  },
  dataPerformanceClosingAverageBw: state => {
    return state.dataPerformanceClosingAverageBw
  },
  dataPerformanceClosingHarvestAge: state => {
    return state.dataPerformanceClosingHarvestAge
  },
  dataClosingProduction: state => {
    return state.dataClosingProduction
  },
  dataCurrentProduction: state => {
    return state.dataCurrentProduction
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
