import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import user from './modules/user'
import chickenCategory from './modules/chicken-category'
import cageCategory from './modules/cage-category'
import feedCategory from './modules/feed-category'
import activity from './modules/activity'
import activityCategory from './modules/activity-category'
import detailActivityCategory from './modules/detail-activity-category'
import subActivityCategory from './modules/sub-activity-category'
import categorySop from './modules/category-sop'
import detailSop from './modules/detail-sop'
import city from './modules/city'
import farm from './modules/farm'
import cage from './modules/cage'
import sapronak from './modules/sapronak'
import branch from './modules/branch'
import activityCage from './modules/activity-cage'
import merkDoc from './modules/merk-doc'
import activityLog from './modules/activity-log'
import activityLogDetail from './modules/activity-log-detail'
import masterActivityDetail from './modules/master-detail-activity'
import cycle from './modules/cycle'
import role from './modules/role'
import inputType from './modules/input-type'
import ovkCategory from './modules/ovk-category'
import contractPrice from './modules/contract-price'
import harvestRequest from './modules/harvest-request'
import setting from './modules/setting'
import vendor from './modules/vendor'
import rhpp from './modules/rhpp'
import acquisition from './modules/acquisition'
import acquisitionDashboard from './modules/acquisition-dashboard'
import productionDashboard from './modules/production-dashboard'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {},
  modules: {
    auth,
    user,
    chickenCategory,
    cageCategory,
    feedCategory,
    activity,
    activityCategory,
    subActivityCategory,
    detailActivityCategory,
    categorySop,
    detailSop,
    city,
    farm,
    cage,
    sapronak,
    harvestRequest,
    branch,
    activityCage,
    merkDoc,
    activityLog,
    activityLogDetail,
    masterActivityDetail,
    cycle,
    role,
    inputType,
    ovkCategory,
    contractPrice,
    setting,
    vendor,
    rhpp,
    acquisition,
    acquisitionDashboard,
    productionDashboard
  }
})
