<template>
  <div
    id="toggle-animation"
    :hidden="screenWidth"
    :class="{'width-large': isShow, 'width-small': !isShow, 'card-sidebar': true}"
  >
    <div class="sidebar uk-card uk-card-default uk-card-body uk-height-1-1">
      <div class="uk-flex uk-flex-between">
        <img
          v-lazy="isShow ? `${images}/icon/logowithtext.svg` : `${images}/icon/logo.svg`"
          alt=""
          class="uk-margin-medium-bottom"
        >
        <a
          v-if="screenWidth"
          style="margin-top:5px;"
          class="header-link"
          uk-toggle="target: #toggle-animation; animation: uk-animation-slide-left"
        >
          <img
            v-lazy="`${images}/icon/close.svg`"
            alt="icon-close"
            class="uk-margin-medium-bottom"
          >
        </a>
      </div>
      
      <div
        v-if="isShow && isShowTitleDashboard"
        class="text-white"
      >
        Dashboard
      </div>

      <ul
        v-if="isShow"
        id="sidebar-dashboard"
        class="uk-nav-default uk-nav-parent-icon"
        uk-nav
      >
        <li
          v-for="items in filterSidebar"
          :key="items.name"
          :class="showSidebarClass(items.to, items.childDisplay)"
        >
          <router-link
            :id="items.identifier"
            :to="items.to"
            @click.native="goTo(items.to)"
          >
            <span class="uk-margin-small-right">
              <img
                v-lazy="items.icon ? `${images}/icon/${items.icon}.svg` : `${images}/icon/home.svg`"
                alt=""
                :class="{'uk-margin-small-left' : isShow}"
                class="sidebaricon"
              >
            </span>{{ items.name }} 
          </router-link>
          <ul
            v-if="items.children.length > 0"
            class="uk-nav-sub sidebar-padding"
          >
            <li
              v-for="child in items.children"
              :key="child.name"
              class="sidebar-padding-child"
              :class="showSidebarClassForDashboard(child.to)"
            >
              <router-link
                :id="String(child.identifier)"
                :key="child.name"
                :to="child.to"
                @click.native="goTo(child.to)"
              >
                <span
                  class="uk-margin-small-right"
                  :uk-icon="child.icon"
                />
                {{ child.name }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    
      <ul
        v-else
        class="uk-nav-default uk-nav-parent-icon"
        uk-nav
      >
        <li
          v-for="items in filterSidebar"
          :key="items.name"
          :class="getSidebarClass(items.to)"
        >
          <router-link
            :id="String(items.identifier)"
            :to="items.to"
            @click.native="goTo(items.to)"
          >
            <span>
              <img
                v-lazy="items.icon ? `${images}/icon/${items.icon}.svg` : `${images}/icon/home.svg`"
                alt=""
                :class="{'uk-margin-small-left' : isShow}"
                class="sidebaricon"
              >
            </span>
          </router-link>
        </li>
      </ul>
      <br>

      <span
        v-if="isShow && isShowTitleFarm"
        class="text-white"
      >Peternakan</span>
      <ul
        v-if="isShow"
        id="sidebar-farm"
        class="uk-nav-default uk-nav-parent-icon"
        uk-nav
      >
        <li
          v-for="items in filterSidebarFarm"
          :key="items.name"
          :class="getSidebarClass(items.to)"
        >
          <router-link
            :id="String(items.identifier)"
            :to="items.to"
            @click.native="goTo(items.to)"
          >
            <span class="uk-margin-small-right">
              <img
                v-lazy="items.icon ? `${images}/icon/${items.icon}.svg` : `${images}/icon/home.svg`"
                alt=""
                :class="{'uk-margin-small-left' : isShow}"
                class="sidebaricon"
              >
            </span>
            {{ items.name }}
          </router-link>
        </li>
      </ul>
      
      <ul
        v-else
        class="uk-nav-default uk-nav-parent-icon"
        uk-nav
      >
        <li
          v-for="items in filterSidebarFarm"
          :key="items.name"
          :class="getSidebarClass(items.to)"
        >
          <router-link
            :id="String(items.identifier)"
            :to="items.to"
            @click.native="goTo(items.to)"
          >
            <span>
              <img
                v-lazy="items.icon ? `${images}/icon/${items.icon}.svg` : `${images}/icon/home.svg`"
                alt=""
                :class="{'uk-margin-small-left' : isShow}"
                class="sidebaricon"
              >
            </span>
          </router-link>
        </li>
      </ul>

      <br>

      <span
        v-if="isShow && isShowTitleMaster"
        class="text-white"
      >Master Data</span>
      <ul
        v-if="isShow"
        id="sidebar-master"
        class="uk-nav-default uk-nav-parent-icon"
        uk-nav
      >
        <li
          v-for="items in filterSidebarMaster"
          :key="items.name"
          :class="showSidebarClass(items.to, items.childDisplay)"
        >
          <router-link
            :id="items.identifier"
            :to="items.to"
            @click.native="goTo(items.to)"
          >
            <span class="uk-margin-small-right">
              <img
                v-lazy="items.icon ? `${images}/icon/${items.icon}.svg` : `${images}/icon/home.svg`"
                alt=""
                :class="{'uk-margin-small-left' : isShow}"
                class="sidebaricon"
              >
            </span>
            {{ items.name }}
          </router-link>
          <ul
            v-if="items.children.length > 0"
            class="uk-nav-sub sidebar-padding"
          >
            <li
              v-for="child in items.children"
              :key="child.name"
              class="sidebar-padding-child"
              :class="showSidebarClass(items.to, items.childDisplay,child.to)"
            >
              <router-link
                :id="String(child.identifier)"
                :key="child.name"
                :to="child.to"
                @click.native="goTo(child.to)"
              >
                <span
                  class="uk-margin-small-right"
                  :uk-icon="child.icon"
                />
                {{ child.name }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
      
      <ul
        v-else
        class="uk-nav-default uk-nav-parent-icon"
        uk-nav
      >
        <li
          v-for="items in filterSidebarMaster"
          :key="items.name"
          :class="getSidebarClass(items.to)"
        >
          <router-link
            :to="items.to"
            @click.native="goTo(items.to)"
          >
            <span>
              <img
                v-lazy="items.icon ? `${images}/icon/${items.icon}.svg` : `${images}/icon/home.svg`"
                alt=""
                :class="{'uk-margin-small-left' : isShow}"
                class="sidebaricon"
              >
            </span>
          </router-link>
        </li>
      </ul>

      <br>

      <span
        v-if="isShow && isShowTitleUser"
        class="text-white"
      >User</span>
      <ul
        v-if="isShow"
        id="sidebar-user"
        class="uk-nav-default uk-nav-parent-icon"
        uk-nav
      >
        <li
          v-for="items in filterSidebarUser"
          :key="items.name"
          :class="getSidebarClass(items.to)"
        >
          <router-link
            :id="String(items.identifier)"
            :to="items.to"
            @click.native="goTo(items.to)"
          >
            <span class="uk-margin-small-right">
              <img
                v-lazy="items.icon ? `${images}/icon/${items.icon}.svg` : `${images}/icon/home.svg`"
                alt=""
                :class="{'uk-margin-small-left' : isShow}"
                class="sidebaricon"
              >
            </span>
            {{ items.name }}
          </router-link>
        </li>
      </ul>
      
      <ul
        v-else
        class="uk-nav-default uk-nav-parent-icon"
        uk-nav
      >
        <li
          v-for="items in filterSidebarUser"
          :key="items.name"
          :class="getSidebarClass(items.to)"
        >
          <router-link
            :id="String(items.identifier)"
            :to="items.to"
            @click.native="goTo(items.to)"
          >
            <span>
              <img
                v-lazy="items.icon ? `${images}/icon/${items.icon}.svg` : `${images}/icon/home.svg`"
                alt=""
                :class="{'uk-margin-small-left' : isShow}"
                class="sidebaricon"
              >
            </span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import sidebarJson from "./sidebar.json"
import sidebarFarmJson from "./sidebar-farm.json"
import sidebarUserJson from "./sidebar-user.json"
import sidebarMasterJson from "./sidebar-master.json"
import { getUserRoleInfo } from '@/utils/auth'

export default {
  data() {
    return {
      isShow: true,
      isShowTitleDashboard: true,
      isShowTitleFarm: true,
      isShowTitleMaster: true,
      isShowTitleUser: true,
      images: PREFIX_IMAGE,
      environment: process.env.VUE_APP_ENV,
      sidebar: sidebarJson,
      sidebarFarm: sidebarFarmJson,
      sidebarUser: sidebarUserJson,
      sidebarMaster: sidebarMasterJson,
      userRoleInfo: JSON.parse(getUserRoleInfo()),
      screenWidth: false
    }
  },
  computed: {
    filterSidebar: function(){
      const sider = this.sidebar.filter(item => {
        if (item.role.includes('super_admin')) {
          if (this.userRoleInfo.role.Name !== 'Super Admin') {
            const showMenuView = this.userRoleInfo.role_management.find(x => x.name === item.name)
            const menuView = showMenuView ? showMenuView.view : false
            if (menuView) {
              if (this.environment) {
                if (item.showOn.includes(this.environment)) return item
                return false
              }
              return item
            } else {
              this.isShowTitleDashboard = false
            }
          } else {
            if (this.environment) {
              if (item.showOn.includes(this.environment)) return item
              return false
            }
            return item
          }
        }
      })
      return sider
    },
    filterSidebarFarm: function(){
      let countShowTitle = 0
      const sider = this.sidebarFarm.filter(item => {
        if (item.role.includes('super_admin')) {
          if (this.userRoleInfo.role.Name !== 'Super Admin') {
            const showMenuView = this.userRoleInfo.role_management.find(x => x.name === item.name)
            const menuView = showMenuView ? showMenuView.view : false
            if (menuView) {
              if (this.environment) {
                if (item.showOn.includes(this.environment)) return item
                return false
              }
              return item
            } else {
              countShowTitle += 1
            }
            this.isShowTitleFarm = countShowTitle === this.sidebarFarm.length ? false : true
          } else {
            if (this.environment) {
              if (item.showOn.includes(this.environment)) return item
              return false
            }
            return item
          }
        }
      })
      return sider
    },
    filterSidebarUser: function(){
      let countShowTitle = 0
      const sider = this.sidebarUser.filter(item => {
        if (item.role.includes('super_admin')) {
          if (this.userRoleInfo.role.Name !== 'Super Admin') {
            const showMenuView = this.userRoleInfo.role_management.find(x => x.name === item.name)
            const menuView = showMenuView ? showMenuView.view : false
            if (menuView) {
              if (this.environment) {
                if (item.showOn.includes(this.environment)) return item
                return false
              }
              return item
            } else {
              countShowTitle += 1
            }
            this.isShowTitleUser = countShowTitle === this.sidebarUser.length ? false : true
          } else {
            if (this.environment) {
              if (item.showOn.includes(this.environment)) return item
              return false
            }
            return item
          }
        }
      })
      return sider
    },
    filterSidebarMaster: function(){
      let countShowTitle = 0
      const sider = this.sidebarMaster.filter(item => {
        if (item.role.includes('super_admin')) {
          if (this.userRoleInfo.role.Name !== 'Super Admin') {
            if (item.childDisplay) {
              const siderChild = item.children.filter(z => {
                const findChild = this.userRoleInfo.role_management.find(x => x.name === z.name)
                const menuView = findChild ? findChild.view : false
                if (menuView) return findChild
              })
              item.children = siderChild
              item['view'] = siderChild.length > 0 ? true : false
              if (item.view) {
                if (this.environment) {
                  if (item.showOn.includes(this.environment)) return item
                  return false
                }
                return item
              } else {
                countShowTitle += 1
              }
            } else {
              const showMenuView = this.userRoleInfo.role_management.find(x => x.name === item.name)
              const menuView = showMenuView ? showMenuView.view : false
              if (menuView) {
                if (this.environment) {
                  if (item.showOn.includes(this.environment)) return item
                  return false
                }
                return item
              } else {
                countShowTitle += 1
              }
            }
            this.isShowTitleMaster = countShowTitle === this.sidebarMaster.length ? false : true
          } else {
            if (this.environment) {
              if (item.showOn.includes(this.environment)) return item
              return false
            }
            return item
          }
        }
      })
      return sider
    }
  },
  mounted() {
    this.handleWidthScreen(screen.width)
  },
  methods: {
    ...mapMutations({
      setMetaFarm: 'farm/SET_META',
      setMetaCage: 'cage/SET_META',
      setMetaActivityLog: 'activityLog/SET_META',
      setMetaActivityCage: 'activityCage/SET_META',
      setMetaActivityLogDetail: 'activityLogDetail/SET_META',
      setMetaSapronak: 'sapronak/SET_META',
      setMetaCageCategory: 'cageCategory/SET_META',
      setMetaOvkCategory: 'ovkCategory/SET_META',
      setMetaContractPrice: 'contractPrice/SET_META',
      setMetaVendor: 'vendor/SET_META',
      setMetaMerkDoc: 'merkDoc/SET_META',
      setMetaBranch: 'branch/SET_META',
      setMetaActivity: 'activity/SET_META',
      setMetaActivityCategory: 'activityCategory/SET_META',
      setMetaSubActivityCategory: 'subActivityCategory/SET_META',
      setMetaDetailActivityCategory: 'detailActivityCategory/SET_META',
      setMetaCategorySop: 'categorySop/SET_META',
      setMetaDetailSop: 'detailSop/SET_META',
      setMetaUser: 'user/SET_META_USER',
      setMetaRole: 'user/SET_META',
      setMetaRhpp: 'rhpp/SET_META'
    }),
    handleWidthScreen(value) {
      this.screenWidth = value <= 820
    },
    handleToggleResponsive() {
      if (this.screenWidth) {
        window.UIkit.toggle('#toggle-animation', {
          target: '#toggle-animation',
          animation : 'uk-animation-slide-left'
        })
      }
    },
    showDetailSidebar() {
      this.isShow = !this.isShow
    },
    getSidebarClass(path){
      return {
        'active': this.$route.fullPath.indexOf(path) > -1,
        'inactive': this.$route.fullPath.indexOf(path) <= -1
      }
    },
    showChildSidebar(child) {
      return child ? 'uk-parent' : ''
    },
    showSidebarClass (path, child,childpath) {
      const active = this.getSidebarClass(path)
      const activechild = this.getSidebarClass(childpath)
      const childs = this.showChildSidebar(child)
      const classes = [active,childs,activechild]
      return classes
    },
    showSidebarClassForDashboard(childPath) {
      return {
        'active' : this.$route.fullPath == childPath,
        'inactive': this.$route.fullPath != childPath
      }
    },
    goTo(path) {
      switch (path) {
      case '/admin/dashboard':
        this.handleToggleResponsive()
        break
      case '/admin/dashboard-acquisition':
        this.handleToggleResponsive()
        break
      case '/admin/daily-resume':
        this.handleToggleResponsive()
        break
      case '/admin/peternakan':
        this.setMetaFarm({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/kandang-ayam':
        this.setMetaCage({page:1, limit:10})
        this.setMetaActivityLog({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/kegiatan-kandang':
        this.setMetaActivityCage({page:1, limit:10})
        this.setMetaActivityLogDetail({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/sapronak':
        this.setMetaSapronak({page:1, limit:10, transaction_type: 'delivery'})
        this.handleToggleResponsive()
        break
      case '/admin/permintaan-panen':
        // this.setMetaActivityCage({page:1, limit:10})
        // this.setMetaActivityLogDetail({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/jenis-kandang':
        this.setMetaCageCategory({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/jenis-pakan':
        this.setMetaCageCategory({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/jenis-ovk':
        this.setMetaOvkCategory({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/harga-kontrak':
        this.setMetaContractPrice({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/vendor':
        this.setMetaVendor({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/merk-doc':
        this.setMetaMerkDoc({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/cabang':
        this.setMetaBranch({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/tahapan':
        this.setMetaActivity({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/kategori-kegiatan':
        this.setMetaActivityCategory({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/rhpp':
        this.setMetaRhpp({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/sub-kategori-kegiatan':
        this.setMetaSubActivityCategory({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/detail-kegiatan':
        this.setMetaDetailActivityCategory({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/kategori-sop':
        this.setMetaCategorySop({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/detail-sop':
        this.setMetaDetailSop({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/user':
        this.setMetaUser({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/role':
        this.setMetaRole({page:1, limit:10})
        this.handleToggleResponsive()
        break
      case '/admin/settings':
        this.handleToggleResponsive()
        break
      default:
        break
      }
    }
  }
}
</script>

<style scoped>
.sidebaricon {
  height: 20px;
  width: 20px;
}

#toggle-animation {
  transition: width 500ms;
}
.detail-sidebar {
  padding-right: 10%;
  background-color: #025231;
  height: 50px;
  line-height: 50px;
}
.detail-sidebar a {
  color: #ffffff;
}
.sidebar {
  overflow: auto;
  background-color: #025231;
}
li {
  padding: 8px 0;
  border-top: 1px solid #025231;
}
li:last-child {
  border-bottom: 1px solid #025231;
}
.uk-nav-header {
  color: #fff;
}
.uk-nav-default li a.just-icon {
  padding: 0;
  text-align: center;
}
.active{
  background-color: #004428;
  color: #004428;
}
.active > a{
  color: #fff !important;
}
.uk-nav-default .uk-nav-sub a {
    color: #fff;
}
.uk-nav-default > li > a {
  color: #fff;
}

.uk-nav-default > li > a:hover, .uk-nav-default > li > a:focus {
  color: #84deb9;
}
.sidebar-padding-child {
  padding-left: 55px !important;
}
.sidebar-padding {
  padding-left: 0px !important;
}
::after {
  filter: brightness(0) invert(1)
}
</style>
