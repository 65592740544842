const getHeaderTitle = (fullpath) => {
  if (fullpath.search('profile')>0) {
    return 'Profil Saya'
  } if (fullpath.search('change-password')>0) {
    return 'Ganti Password'
  } if (fullpath == '/admin/dashboard-acquisition') {
    return 'Akuisisi'
  } if (fullpath == '/admin/dashboard-production') {
    return 'Produksi'
  } if (fullpath.search('dashboard')>0) {
    return 'Dashboard'
  } if (fullpath.search('daily-resume')>0) {
    return 'Daily Resume'
  } if (fullpath.search('role')>0) {
    return 'Role'
  } if (fullpath.search('user')>0) {
    return 'User'
  } if(fullpath.search('settings')>0) {
    return 'Settings'
  } if (fullpath.search('jenis-ayam')>0) {
    if (fullpath.search('jenis-ayam/import')>0){
      return 'Import Jenis Ayam'
    }
    return 'Jenis Ayam'
  } if (fullpath.search('jenis-kandang')>0) {
    if (fullpath.search('jenis-kandang/import')>0){
      return 'Import Jenis Kandang'
    }
    return 'Jenis Kandang'
  } if (fullpath.search('jenis-pakan')>0) {
    return 'Jenis Pakan'
  } if (fullpath.search('tahapan')>0) {
    if (fullpath.search('tahapan/import')>0){
      return 'Import Tahapan'
    }
    return 'Tahapan'
  } if (fullpath.search('/kategori-kegiatan')>0) {
    if (fullpath.search('/kategori-kegiatan/import')>0){
      return 'Import Kategori Kegiatan'
    }
    return 'Kategori Kegiatan'
  } if (fullpath.search('/sub-kategori-kegiatan')>0) {
    if (fullpath.search('/sub-kategori-kegiatan/import')>0){
      return 'Import Sub Kategori Kegiatan'
    }
    return 'Sub Kategori Kegiatan'
  } if (fullpath.search('detail-kegiatan')>0) {
    if (fullpath.search('detail-kegiatan/import')>0){
      return 'Import Detail Kegiatan'
    }
    return 'Detail Kegiatan'
  } if (fullpath.search('/kategori-sop')>0) {
    if (fullpath.search('/kategori-sop/import')>0){
      return 'Import Kategori SOP'
    }
    return 'Kategori SOP'
  } if (fullpath.search('/detail-sop')>0) {
    if (fullpath.search('/detail-sop/import')>0){
      return 'Import Detail SOP'
    }
    return 'Detail SOP'
  } if (fullpath.search('/peternakan')>0) {
    if (fullpath.search('/peternakan/import')>0){
      return 'Import Peternakan'
    }
    return 'Peternakan'
  } if (fullpath.search('/kandang-ayam')>0) {
    return 'Kandang Ayam'
  } if (fullpath.search('/kegiatan-kandang')>0) {
    return 'Kegiatan Kandang'
  } if (fullpath.search('/permintaan-panen')>0) {
    return 'Permintaan Panen'
  } if (fullpath.search('/rhpp/add') > 0) {
    return 'Tambah RHPP'
  } if (fullpath.search('/rhpp/edit') > 0) {
    return 'Ubah RHPP'
  } if (fullpath.search('/rhpp/detail') > 0) {
    return 'Detail'
  } if (fullpath.search('/rhpp') > 0) {
    return 'Rekapitulasi Hasil Produksi Peternak'
  } if (fullpath.search('/merk-doc')>0) {
    return 'Merek DOC'
  } if (fullpath.search('/harga-kontrak')>0) {
    return 'Harga Kontrak'
  } if (fullpath.search('/vendor')>0) {
    return 'Vendor'
  } if (fullpath.search('/sapronak')>0) {
    return 'Permintaan Sapronak'
  } if (fullpath.search('/cabang')>0) {
    return 'Cabang' 
  } if (fullpath.search('/akuisisi/add') > 0) {
    return 'Tambah Mitra' 
  } if (fullpath.search('/akuisisi/edit') > 0) {
    return 'Ubah Mitra' 
  } if (fullpath.search('/akuisisi/detail') > 0) {
    return 'Detail Mitra' 
  } if (fullpath.search('/akuisisi') > 0) {
    return 'Akuisisi' 
  } else {
    return 'Dashboard'
  }
}

export default getHeaderTitle
