<template>
  <div
    class="uk-width-1-1@xs uk-width-1-2@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl"
  >
    <div class="uk-card uk-card-default kandang-card">
      <h4 class="label-green card-title">
        Closing Production
      </h4>
      <div class="uk-grid">
        <div class="uk-width-1-2">
          <div class="uk-flex uk-flex-center">
            Bulan ini
          </div>
          <div class="wrapper-chart">
            <Doughnut
              :chart-data="chartData"
              :options="doughnutOption"
            />
            <h1 class="first">
              Avg. IP
            </h1><h1>{{ avgIpCurrentMonth }}</h1>
          </div>
        </div>
        <div class="uk-width-1-2 vertical-line">
          <div class="uk-flex uk-flex-center">
            Akumulasi
          </div>
          <div class="uk-flex uk-flex-center">
            <h4 class="uk-margin-remove-bottom uk-margin-top uk-text-bold">
              Avg. Ip
            </h4>
          </div>
          <div class="uk-flex uk-flex-center uk-text-bold">
            <h1>{{ avgIpAccumulation }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Doughnut from '@/components/globals/chart/DoughnutChart.js'
import { mapGetters } from 'vuex'

export default{
  components: {
    Doughnut
  },
  data(){
    return {
      avgIpCurrentMonth:'',
      avgIpAccumulation:'',
      doughnutOption: {
        responsive: true, 
        maintainAspectRatio: false, 
        animation: {
          animateRotate: true
        },
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex]
              var currentValue = dataset.data[tooltipItem.index]
              var label = data.labels[tooltipItem.index]
              return  label + ': ' + currentValue + '%'
            }
          }
        }
      },
      chartData: {}
    }
  },
  computed:{
    ...mapGetters({
      dataClosingProductions: 'productionDashboard/dataClosingProduction'
    })
  },
  watch: {
    dataClosingProductions(){
      const dataChart = this.dataClosingProductions.closing_production_chart.grade_point_limit
      const chickenIps = this.dataClosingProductions.number_of_chickens
      this.chartData = {
        labels: ['Lower', 'Upper'],
        datasets: [
          {
            backgroundColor: ['#52E0A5', '#004428'],
            data: [dataChart.lower, dataChart.upper]
          }
        ]
      }
      this.avgIpCurrentMonth = chickenIps.current_month
      this.avgIpAccumulation = chickenIps.accumulation
    }
  }
}

</script>

<style scoped>
.vertical-line {
  border-left: 1px solid #a8a5a585;
}

.wrapper-chart {
    padding: 0 38px;
  }
  .wrapper-chart h1 {
    font-family: ClanOT;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #000000;
    line-height: 0;
    width: 100%;
    word-wrap: break-word;
    text-align: center;
    position: absolute;
    top: 65%;
    left: -120px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper-chart .first {
    font-family: ClanOT;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #000000;
    line-height: 0;
    width: 100%;
    word-wrap: break-word;
    text-align: center;
    position: absolute;
    top: 58%;
    left: -120px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
</style>
