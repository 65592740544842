<template>
  <div class="uk-width-1-2 uk-padding-large uk-padding-remove-right uk-padding-remove-top">
    <div
      class="uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-6 label-green uk-text-bold uk-margin-small-bottom">
        {{ chartProps.title }}
      </div>
      <div class="uk-width-5-6">
        <ul class="horizontal-list">
          <li class="first">
            <span>Akumulasi : <span class="uk-text-bold">{{ thousandSeparator(chartProps.acqumulation) || chartProps.acqumulation }}</span> {{ chartProps.unit }}</span>
          </li>
          <li>
            <span>Bulan ini : <span class="uk-text-bold">{{ thousandSeparator(chartProps.currentMonth) || chartProps.currentMonth }}</span> {{ chartProps.unit }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="rotate-left uk-margin-small-right">
      {{ chartProps.yLegend }}
    </div>
    <bar-line-chart
      :chart-data="chartData"
      :options="chartOptions"
    />
    <div class="uk-width-1-1 uk-text-center text-black">
      Bulan
    </div>
  </div>
</template>

<script>
import BarLineChart from '@/components/globals/chart/BarLineChart'
import {thousandSeparator} from "@/utils/formater"

export default{
  components:{
    BarLineChart
  },
  props:{
    chartProps: {
      required: true,
      type: Object
    }
  },
  data(){
    return{
      chartData:{},
      chartOptions: {}
    }
  },
  watch:{
    chartProps(){
      this.chartData = {
        labels: this.splitLabels(this.chartProps.range),
        datasets: [
          {
            label: this.chartProps.title,
            backgroundColor: this.chartProps.backgroundColor,
            borderWidth: 1,
            data: this.chartProps.data
          }
        ]
      }
      this.chartOptions = {
        plugins: {
          tooltip: {
            callbacks: {
              title:(context) => {
                console.log(context)
                return context[0].label.replaceAll(',',' ')
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true
          }
        },
        legend: {
          display: false
        }
      }
    }
  },
  methods:{
    thousandSeparator(number) {
      return thousandSeparator(number)
    },
    splitLabels (arr) {
      return arr.map(label => label.split(' '))
    }
  }
}
</script>

<style scoped>
.horizontal-list {
  list-style-type: disc;
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.first {
  margin-right: 20px !important; 
}

.rotate-left {
  position: relative !important;
  top: 50%;
  left: -20px;
  transform-origin: left center;
  transform: rotate(-90deg);
  white-space: nowrap;
  font-size: 14px; 
}
</style>
